import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Text,
  useBreakpointValue,
  VStack
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

import { HEADER_HEIGHT, NAVBAR_WIDTH } from '../../../constants/components';
import { DEFAULT_SECTION, NAV_BAR_ITEMS } from '../../../constants/navigation';
import { NavbarItem, NavBarProps } from '../../../types/components';
import { useUserData } from '../../../contexts/UserDataContext';

const NavBar: React.FC<NavBarProps> = ({ isOpen, onClose = () => {} }) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const isMobile = useBreakpointValue({ base: true, desktopNav: false });
  const { pathname } = useLocation();
  // @ts-ignore
  const { userData } = useUserData();

  const [openItems, setOpenItems] = useState<Record<string, boolean>>({});

  const handleNavigate = (route: string) => {
    push(route);
    onClose();
  };

  const toggleSubItems = (label: string) => {
    setOpenItems((prevState) => ({
      ...prevState,
      [label]: !prevState[label]
    }));
  };

  const hasActiveSubItem = (parentHref: string, items?: { href: string }[]) => {
    if (items?.some((subItem) => pathname === subItem.href)) {
      return true;
    }

    return pathname === parentHref;
  };

  const renderMenuItems = () => {
    const filteredNavbarItems = NAV_BAR_ITEMS.filter((link) => {
      const { isEnterprise, isCompanyAdmin, isContentCreator, isClinician } =
        userData || {};

      return (
        (!link.isEnterprise || isEnterprise) &&
        (!link.isAdmin || isCompanyAdmin) &&
        (!link.isContentCreator || isContentCreator) &&
        (!link.isClinician || isClinician)
      );
    }).map((link) => {
      const initialLink = {
        ...link
      };

      if (link.items) {
        initialLink.items = link.items
          .filter((item) =>
            item.href === '/group_insights' && userData?.hideGroupInsightsMenu
              ? false
              : item
          )
          .filter((item) =>
            userData?.isClinician &&
            !userData?.isClinicianAdmin &&
            item.onlyAdmin
              ? false
              : item
          );
      }

      return initialLink;
    });

    const sections = filteredNavbarItems.reduce((acc, item) => {
      if (!acc[item.section]) {
        acc[item.section] = [];
      }
      acc[item.section].push(item);
      return acc;
    }, {} as Record<string, typeof NAV_BAR_ITEMS>);

    const handleOnClickButton = (item: NavbarItem) => {
      if (item.items) toggleSubItems(item.label);
      else handleNavigate(item.href);
    };

    return Object.entries(sections).map(([section, items], index) => (
      <Box key={index} w="full">
        {section !== DEFAULT_SECTION && (
          <Text
            textTransform="uppercase"
            color="ds.text.general.800"
            my={4}
            ml={4}
            fontSize="s"
          >
            {t(section)}
          </Text>
        )}

        {items.map((item) => {
          const isExpanded = openItems[item.label];
          const isActive = pathname === item.href;

          return (
            <Box key={item.label} w="full">
              <Button
                w="full"
                variant="ghost"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={4}
                borderRadius={0}
                color={
                  isActive || hasActiveSubItem(item.href, item.items)
                    ? 'ds.neutral.gray.0'
                    : 'ds.neutral.gray.800'
                }
                bg={(() => {
                  if (isActive || hasActiveSubItem(item.href, item.items)) {
                    return 'ds.brand.primary.800';
                  }
                  if (hasActiveSubItem(item.href, item.items)) {
                    return 'ds.brand.primary.200';
                  }
                  return 'transparent';
                })()}
                _hover={{
                  bg:
                    isActive || hasActiveSubItem(item.href, item.items)
                      ? 'ds.brand.primary.1000'
                      : 'ds.brand.primary.200',
                  color:
                    isActive || hasActiveSubItem(item.href, item.items)
                      ? 'ds.neutral.gray.0'
                      : 'ds.neutral.gray.1000',
                  '.chakra-button__icon *': {
                    color:
                      isActive || hasActiveSubItem(item.href, item.items)
                        ? 'ds.neutral.gray.0'
                        : 'ds.brand.primary.1000'
                  }
                }}
                sx={{
                  '.chakra-button__icon *': {
                    color:
                      isActive || hasActiveSubItem(item.href, item.items)
                        ? 'ds.neutral.gray.0'
                        : 'ds.brand.primary.800'
                  }
                }}
                onClick={() => handleOnClickButton(item)}
                leftIcon={
                  <Box
                    width="24px"
                    height="24px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mr={1}
                  >
                    {item.icon && (
                      <FontAwesomeIcon icon={item.icon} fontSize="1rem" />
                    )}
                  </Box>
                }
                rightIcon={
                  item.items ? (
                    <Box ml="auto">
                      <FontAwesomeIcon
                        icon={isExpanded ? faChevronUp : faChevronDown}
                        fontSize="1rem"
                      />
                    </Box>
                  ) : undefined
                }
              >
                <Box
                  w="full"
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Text fontWeight="400" fontSize="sm">
                    {t(item.label)}
                  </Text>
                </Box>
              </Button>
              {item.items && item.items.length > 0 && (
                <Collapse in={openItems[item.label]}>
                  <Box w="full">
                    {item.items.map((subItem) => {
                      const isSubItemActive = pathname === subItem.href;
                      return (
                        <Button
                          key={subItem.label}
                          w="100%"
                          overflow="hidden"
                          variant="ghost"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          borderRadius={0}
                          bg="transparent"
                          color={
                            isSubItemActive
                              ? 'ds.brand.primary.800'
                              : 'ds.neutral.gray.800'
                          }
                          _hover={{
                            bg: 'ds.brand.primary.200',
                            color: isSubItemActive
                              ? 'ds.brand.primary.1000'
                              : 'ds.neutral.gray.1000'
                          }}
                          onClick={() => handleNavigate(subItem.href)}
                          leftIcon={
                            <Box
                              width="24px"
                              height="24px"
                              color={
                                isSubItemActive
                                  ? 'ds.brand.primary.800'
                                  : 'ds.neutral.gray.800'
                              }
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              mr={1}
                              _hover={{
                                bg: 'ds.brand.primary.200',
                                color: isSubItemActive
                                  ? 'ds.brand.primary.1000'
                                  : 'ds.neutral.gray.1000'
                              }}
                            >
                              {isSubItemActive && (
                                <FontAwesomeIcon
                                  icon={faCircle as any}
                                  fontSize={5}
                                />
                              )}
                            </Box>
                          }
                        >
                          <Box
                            w="full"
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Text fontWeight="400" fontSize="sm">
                              {t(subItem.label)}
                            </Text>
                          </Box>
                        </Button>
                      );
                    })}
                  </Box>
                </Collapse>
              )}
              <Divider borderColor="ds.neutral.gray.300" />
            </Box>
          );
        })}
      </Box>
    ));
  };

  return isMobile ? (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent bg="ds.neutral.gray.0">
        <DrawerCloseButton color="ds.neutral.gray.800" />
        <DrawerBody p={0} mt={12}>
          <VStack spacing={0} align="start">
            {renderMenuItems()}
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  ) : (
    <Box
      position="fixed"
      left={0}
      top={HEADER_HEIGHT}
      width={NAVBAR_WIDTH}
      height={`calc(100vh - ${HEADER_HEIGHT})`}
      bg="ds.neutral.gray.0"
      zIndex={1000}
      shadow="level-01"
      transform={isOpen ? 'translateX(0)' : 'translateX(-100%)'}
      transition="transform 0.3s ease-in-out"
    >
      <VStack spacing={0} align="start">
        {renderMenuItems()}
      </VStack>
    </Box>
  );
};

export { NavBar };
