import {
  ApolloError,
  LazyQueryExecFunction,
  OperationVariables
} from '@apollo/client';
import { GroupProps } from './group';

export type WhiteLabelAppProps = {
  id: string;
  name: string | null;
  slug: string;
  groupId: string;
  ownerGroup: GroupProps | null;
  meta: object | null;
};

export type GetUserWhiteLabelGQL = {
  userWhiteLabel: WhiteLabelAppProps;
};

// eslint-disable-next-line no-shadow
export enum AppSlugEnum {
  // eslint-disable-next-line no-unused-vars
  CHECKINGIN = 'checkingin',
  // eslint-disable-next-line no-unused-vars
  SQUAMISH = 'squamish',
  // eslint-disable-next-line no-unused-vars
  TLAAMING = 'tlaamin',
  // eslint-disable-next-line no-unused-vars
  SQEWLETS = 'sqewlets',
  // eslint-disable-next-line no-unused-vars
  STZUMINUS = 'stzuminus'
}

export type UseWhiteLabelsProps = {
  userWhiteLabel: GetUserWhiteLabelGQL | undefined;
  errorGetUserWhiteLabel?: ApolloError;
  loadingGetUserWhiteLabel: boolean;
  getUserWhiteLabel: LazyQueryExecFunction<
    GetUserWhiteLabelGQL,
    OperationVariables
  >;
};
