import styled from 'styled-components';

export const Tooltip = styled.section`
  width: 20vh;
  background-color: var(--chakra-colors-neutral-0);
  color: ${({ color }) => color || 'var(--chakra-colors-neutral-1100)'};
  border: 2px solid #ccc;
  padding: 0;
  margin: 0;
`;
