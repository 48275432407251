import styled, { css } from 'styled-components';
import { ReactComponent as SpinnerSVG } from '../../assets/svg/spinner.svg';

const wrapperModifier = {
  wrapper: () => css`
    width: 100vw;
    height: 100vh;
  `
};

export const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  ${({ wrapper }) => css`
    ${wrapper && wrapperModifier.wrapper()}
  `}
`;

export const SpinnerIMG = styled(SpinnerSVG)`
  width: 15vh;
  color: var(--chakra-colors-brand-primary, #51a3a4);
  fill: currentColor;
`;

export const SpinnerText = styled.span`
  margin-top: 15px;
  color: var(--chakra-colors-brand-primary);
  font-size: 20px;
  font-weight: 700;
`;
