import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  fetchAndActivate,
  getValue,
  RemoteConfig
} from 'firebase/remote-config';

import { firebaseService } from '../services/firebase';
import { useAppSlugContext } from './AppSlugContext';
import {
  KeyToAnyProps,
  RemoteSettingsContextProps,
  RemoteSettingsProviderProps
} from '../types/contexts';

const RemoteSettingsContext = createContext<RemoteSettingsContextProps>({
  colors: {},
  images: {},
  isLoading: true
});

function useRemoteSettings() {
  const context = useContext(RemoteSettingsContext);

  if (!context) {
    throw new Error(
      'useRemoteSettings must be used within an RemoteSettingsProvider'
    );
  }

  return context;
}

function RemoteSettingsProvider({ children }: RemoteSettingsProviderProps) {
  const { currentAppSlug } = useAppSlugContext();

  const [isLoading, setIsLoading] = useState(true);
  const [remoteConfig, setRemoteConfig] = useState<RemoteConfig | null>(null);
  const [colors, setColors] = useState<KeyToAnyProps>({});
  const [images, setImages] = useState<KeyToAnyProps>({});

  const fetchFirebaseService = async () => {
    const service = await firebaseService(currentAppSlug);
    setRemoteConfig(service.remoteConfig);
  };

  const getAndSetColorsRemote = () => {
    if (!remoteConfig) return;
    const retrievedColors = getValue(remoteConfig, 'colors').asString();

    setColors(JSON.parse(retrievedColors));
  };

  const getAndSetImagesRemote = () => {
    if (!remoteConfig) return;
    const retrievedImages = getValue(remoteConfig, 'images').asString();

    setImages(JSON.parse(retrievedImages));
  };

  useEffect(() => {
    fetchFirebaseService();
  }, [currentAppSlug]);

  useEffect(() => {
    async function fetchRemoteConfig() {
      if (!remoteConfig) return;
      try {
        await fetchAndActivate(remoteConfig);
      } catch (err) {
        console.error('Failed to fetch and activate remote config');
      }
    }

    async function retrieveRemoteConfigs() {
      try {
        getAndSetColorsRemote();
        getAndSetImagesRemote();
      } catch (err) {
        console.error('Failed to get values from remote config');
      }
    }

    const fetchAll = async () => {
      if (!remoteConfig) return;
      if (currentAppSlug) {
        setIsLoading(true);

        try {
          await fetchRemoteConfig();
          await retrieveRemoteConfigs();
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchAll();
  }, [currentAppSlug, remoteConfig]);

  return (
    <RemoteSettingsContext.Provider
      value={{
        colors,
        images,
        isLoading
      }}
    >
      {children}
    </RemoteSettingsContext.Provider>
  );
}

export { RemoteSettingsProvider, useRemoteSettings };
