import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Collapse } from '@mui/material';
import { useQuery } from '@apollo/client';

import { CheckInCard } from '../../components/CheckInCard';
import { ExerciseCard } from '../../components/ExerciseCard';
import LoadingSpinner from '../../components/LoadingSpinner';
import DeleteCheckinModal from '../../components/DeleteCheckin';
import checkinGraph from '../../services/graphql-queries/contentHistory';
import {
  MainContainer,
  Title,
  ButtonToCheckin,
  FilterContainer,
  FilterTitleContainer,
  FilterTitle,
  FilterOptionsContainer,
  FilterOption,
  LoadMore
} from './style';

const History = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);

  const [countPage, setCountPage] = useState(0);

  const [showFilters, setShowFilters] = useState(false);
  const [activeFilters, setActiveFilters] = useState({
    checkins: true,
    exercises: true
  });

  const [modalState, setModalState] = useState({
    isOpen: false,
    deleteTargetId: ''
  });

  const { error, loading, fetchMore } = useQuery(
    checkinGraph.GET_HISTORY_WITH_PAGINATION,
    {
      fetchPolicy: 'no-cache',
      variables: {
        params: {
          page: countPage,
          checkins: activeFilters.checkins,
          exercises: activeFilters.exercises
        }
      },
      onCompleted: (data) => {
        const filteredCheckInCount = activeFilters.checkins
          ? data?.historyWithPagination?.countCheckIn
          : 0;

        const filteredExerciseCount = activeFilters.exercises
          ? data?.historyWithPagination?.countContentHistory
          : 0;

        setTotal(filteredCheckInCount + filteredExerciseCount);
        setItems([...items, ...data?.historyWithPagination?.results]);
      }
    }
  );

  const refetch = async () => {
    setCountPage(0);
    setItems([]);

    await fetchMore({
      variables: {
        checkins: activeFilters.checkins,
        exercises: activeFilters.exercises,
        page: countPage
      }
    });
  };

  const loadMore = async () => {
    setCountPage(countPage + 1);

    await fetchMore({
      variables: {
        checkins: activeFilters.checkins,
        exercises: activeFilters.exercises,
        page: countPage
      }
    });
  };

  const filteredHistoryData = useMemo(
    () =>
      items.reduce((acc, cur) => {
        const isExercise = !!cur.cmsId;
        if (activeFilters.checkins && activeFilters.exercises) {
          acc.push(cur);
        }
        if (!activeFilters.checkins && activeFilters.exercises && isExercise) {
          acc.push(cur);
        }
        if (activeFilters.checkins && !activeFilters.exercises && !isExercise) {
          acc.push(cur);
        }

        return acc;
      }, []),
    [activeFilters, items]
  );

  const handleOnClickFilter = async (key, event) => {
    event.stopPropagation();

    setActiveFilters((prevState) => ({
      ...prevState,
      [key]: !prevState[key]
    }));

    refetch();
  };

  const deleteAction = (id) => () => {
    setModalState({ isOpen: true, deleteTargetId: id });
  };

  const editAction = (checkinData) => () => {
    push('/checkin', {
      action: 'update',
      checkinData
    });
  };

  if (error) {
    return (
      <MainContainer>
        <Title>
          {t(
            'messages.Something wrong happened on load your history but is not your fault just try again'
          )}
        </Title>
      </MainContainer>
    );
  }

  const renderErrorMessage = useCallback(() => {
    if (!loading && total === 0) {
      return (
        <Title style={{ flex: 1, flexDirection: 'colum' }}>
          {(activeFilters.checkins === true &&
            activeFilters.exercises === true) ||
          (activeFilters.checkins && !activeFilters.exercises) ? (
            <div>
              {t('messages.No check ins have been completed')} &nbsp;
              <ButtonToCheckin onClick={() => push('/checkin')}>
                {t('Click here')}
              </ButtonToCheckin>
              &nbsp;{t('messages.to complete your first check in')}
            </div>
          ) : null}
          {(activeFilters.checkins === true &&
            activeFilters.exercises === true) ||
          (activeFilters.exercises && !activeFilters.checkins) ? (
            <div>
              {t('messages.No exercises have been completed')} &nbsp;
              <ButtonToCheckin onClick={() => push('/home')}>
                {t('Click here')}
              </ButtonToCheckin>
              &nbsp;{t('messages.to complete your first exercise')}
            </div>
          ) : null}
          {activeFilters.checkins === false &&
            activeFilters.exercises === false && (
              <div>
                {t(
                  'messages.You have unselected all filter options. Select a filter option to view the history'
                )}
              </div>
            )}
        </Title>
      );
    }
  }, [loading, filteredHistoryData]);

  return (
    <MainContainer>
      <FilterContainer
        onClick={() => setShowFilters((prevState) => !prevState)}
      >
        <FilterTitleContainer>
          <Title>{t('Your History')}</Title>
          <FilterTitle>Filter</FilterTitle>
        </FilterTitleContainer>
        <Collapse in={showFilters} timeout={500}>
          <FilterOptionsContainer>
            <FilterOption
              selected={activeFilters.checkins}
              onClick={(e) => handleOnClickFilter('checkins', e)}
              disabled={loading}
            >
              {t('Check Ins')}
            </FilterOption>
            <FilterOption
              selected={activeFilters.exercises}
              onClick={(e) => handleOnClickFilter('exercises', e)}
              disabled={loading}
            >
              {t('Exercises')}
            </FilterOption>
          </FilterOptionsContainer>
        </Collapse>
      </FilterContainer>

      {renderErrorMessage()}

      {filteredHistoryData.map((item, index) =>
        item.cmsId ? (
          <ExerciseCard
            key={item.id + index}
            contentExercise={item.contentExercise}
            completedDate={item.completedDate}
            rating={item.rating}
            cmsId={item.cmsId}
          />
        ) : (
          <CheckInCard
            key={item.id + index}
            actions
            deleteAction={deleteAction(item.id)}
            editAction={editAction(item)}
            createdDate={item.createdDate}
            energyLevel={item.energyLevel}
            feelingContext={item.feelingContext}
            feelingWord={item.feelingWord}
            weather={item.weather || {}}
          />
        )
      )}

      {loading && <LoadingSpinner />}

      {!loading &&
        total !== 0 &&
        filteredHistoryData.length !== 0 &&
        total > filteredHistoryData.length && (
          <LoadMore onClick={loadMore} disabled={loading}>
            Load more
          </LoadMore>
        )}

      <DeleteCheckinModal
        refetch={refetch}
        isVisible={modalState.isOpen}
        checkinId={modalState.deleteTargetId}
        closeModal={() => setModalState({ isOpen: false, deleteTargetId: '' })}
      />
    </MainContainer>
  );
};

export { History };
