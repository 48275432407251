import styled from 'styled-components';
import { colors } from '@checkingin/component-library';

export const ContainerCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background-color: var(--chakra-colors-neutral-0);
  background-clip: border-box;
  border-radius: 0.25rem;
  border: none;
  box-shadow: 0 2px 6px 0 ${colors.black.lighter};
  text-align: center;
  padding: 1rem;
  margin: 0.625rem 0.625rem 5rem 0.625rem;
  width: 100%;
  min-width: 0;
  max-width: 60rem;
  min-height: 25vh;
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--chakra-colors-neutral-0);
  background-clip: border-box;
  border-radius: 0.25rem;
  border: none;
  box-shadow: 0 2px 6px 0 ${colors.black.lighter};
  text-align: center;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 60rem;
`;

export const CardBody = styled.div`
  padding: 1.25rem;
  margin-bottom: 2rem;
`;

export const HardText = styled.div`
  font-size: 1.25rem;
  padding: 0.625rem;
  color: #636463;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
`;

export const SexyLine = styled.div`
  margin: 25px 50px;
  height: 2px;
  background: var(--chakra-colors-brand-primary);
  background: -webkit-gradient(
    linear,
    0 0,
    100% 0,
    from(white),
    to(white),
    color-stop(50%, var(--chakra-colors-brand-primary))
  );
`;

export const SimpleText = styled.p`
  padding: 10px;
  color: #636463;
  margin-top: 0;
  margin-bottom: 1rem;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: 400;
  line-height: 1.5;
`;
export const LinkText = styled.span`
  color: var(--chakra-colors-brand-primary);
  transition: all 0.25s ease-in-out;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
`;
