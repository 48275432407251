import styled from 'styled-components';
import { breakpoints } from '@checkingin/component-library';

export const ContainerIconText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 0.25rem;
`;

export const Text = styled.span`
  font-family: var(--chakra-fonts-body);
  font-style: normal;
  font-weight: 400;
  font-size: 0.813rem;
  line-height: 1.188rem;
  text-decoration-line: underline;
  color: var(--chakra-colors-brand-primary);

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 1rem;
    line-height: 1.375rem;
  }
`;

export const Image = styled.img.attrs({
  icon: 'icon'
})`
  height: 1rem;
  width: 1rem;
`;
