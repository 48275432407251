import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  padding: 0rem 1.25rem;
  gap: 1rem;
  width: 100%;
`;

export const TitleContainer = styled.div`
  margin: 1.5rem 0rem;
`;

export const TitleText = styled.span`
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 700;
  color: hsl(120, 1%, 39%);
  text-align: center;
  font-family: var(--chakra-fonts-body);
`;

export const DescriptionContainer = styled.div`
  margin-bottom: 1rem;
`;

export const DescriptionText = styled.span`
  font-family: var(--chakra-fonts-body);
  font-size: 1rem;
  line-height: 1.375rem;
  color: hsl(0, 0%, 21%);
`;

export const CorrectAnswersContainer = styled.div`
  margin-top: 1.5rem;
`;

export const ExpectedAnswerText = styled.span`
  font-family: var(--chakra-fonts-body);
  font-size: 1rem;
  line-height: 1.375rem;
  color: var(--chakra-colors-brand-primary);
`;

export const CorrectAnswersText = styled(ExpectedAnswerText)`
  font-family: var(--chakra-fonts-body);
  font-weight: 700;
`;

export const FeedbackContainer = styled(CorrectAnswersContainer)``;

export const FeedbackText = styled(ExpectedAnswerText)`
  color: var(--chakra-colors-neutral-1100);
`;
