import { Colors, ColorHues, RecursiveProperty } from '@chakra-ui/theme';

type ColorDict = RecursiveProperty<string | Record<string, Partial<ColorHues>>>;

const ds: ColorDict = {
  brand: {
    primary: {
      200: '#DCF4F6',
      500: '#85DADB',
      800: '#418384', // brand primary
      1000: '#1F494A'
    },
    secondary: {
      300: '#DBEBED',
      500: '#A9C2C5', // brand secondary
      800: '#506F73',
      1000: '#2A3D40'
    },
    accent: {
      600: '#E26D4C', // accent
      800: '#9E4126'
    }
  },
  neutral: {
    gray: {
      0: '#FFFFFF',
      50: '#F9FCFF',
      100: '#F2F6FA',
      200: '#E5EDF5',
      300: '#C3D0DD',
      500: '#95A2AF', // neutralGray
      800: '#55606B',
      1000: '#2F363E'
    }
  },
  system: {
    success: {
      100: '#EEFFF9',
      600: '#05875A'
    },
    warning: {
      100: '#FFFAEE',
      600: '#DDAD46'
    },
    error: {
      100: '#FFF4F4',
      600: '#D93A3A'
    }
  },
  text: {
    brand: {
      800: '#418384',
      1000: '#1F494A'
    },
    general: {
      500: '#95A2AF',
      800: '#55606B',
      1000: '#2F363E'
    },
    error: {
      800: '#951818'
    }
  },
  spectrum: {
    yellow: {
      500: '#FFCB5C'
    },
    orange: {
      500: '#FF8462'
    }
  }
};

const brand: ColorDict = {
  primary: '#418384',
  secondary: '#F58768',
  0: '#F3F6F6',
  100: '#E7F2F2', // brand light
  200: '#CAD8DA',
  300: '#A9C2C5',
  400: '#80A9AE',
  500: '#418384', // brand primary
  600: '#3A7D83',
  700: '#326C72',
  800: '#29595D',
  900: '#1D3F42'
};

const orange: ColorDict = {
  0: '#FEF6F4',
  100: '#FDECE9',
  200: '#FBD7D0',
  300: '#F9C0B4',
  400: '#F7A693',
  500: '#F58768', // brand secondary
  600: '#E26D4C',
  700: '#BE6951',
  800: '#9B5542',
  900: '#6E3C2F'
};

const yellow: ColorDict = {
  0: '#FEF9F2',
  100: '#FCF4E4',
  200: '#F9E8C6',
  300: '#F6DBA1',
  400: '#F3CD72',
  500: '#E6B92A',
  600: '#D7AB03',
  700: '#BA9402',
  800: '#987902',
  900: '#6B5501'
};

const neutral: ColorDict = {
  0: '#FFFFFF', // pure white
  100: '#FAFAFA',
  200: '#F4F5F5',
  300: '#EAEBEB',
  400: '#DFE2E2',
  500: '#D5D8D8',
  600: '#CACECE',
  700: '#A0A7A7',
  800: '#767F7F',
  900: '#4E5555',
  1000: '#0A0A0A',
  1100: '#000000' // pure black
};

const gradient: ColorDict = {
  base: 'linear-gradient(to top right, #FDC821, #CE6E53)'
};

const feedback: ColorDict = {
  positive: {
    0: '#C6D7CE',
    100: '#05875A',
    200: '#023C28'
  },
  negative: {
    0: '#F1C9C9',
    100: '#D93A3A',
    200: '#611A1A'
  },
  warning: {
    0: '#FFECCE',
    100: '#FFCB5C',
    200: '#51401D'
  }
};

const forgotPassword: ColorDict = {
  topBackgroundColor: '#418C93',
  topTextColor: '#FFFFFF',
  pageBackgroundColor: '#e8ebef'
};

export const colors: Colors = {
  ds,
  brand,
  orange,
  yellow,
  neutral,
  gradient,
  feedback,
  forgotPassword
};
