import styled from 'styled-components';
import { CircularProgressbar } from 'react-circular-progressbar';
import ReactRange from 'react-rangeslider';

export const StepContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1.25rem;
  min-height: inherit;
`;

export const CardBody = styled.div`
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  margin-bottom: 30px;
  background-color: var(--chakra-colors-neutral-0);
  display: flex;
  flex-direction: column;
`;

export const CardTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: auto !important;
  margin-top: auto !important;
`;

export const CardTitle = styled.div`
  padding: 10px;
  color: #636463;
  margin-bottom: 0.75rem;
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.2;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-align: center;
`;
export const CardText = styled.p`
  padding: 10px;
  color: #636463;
  margin-top: 0;
  margin-bottom: 1rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-align: center !important;
  word-wrap: break-word;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
`;

export const NextButton = styled.div`
  background-color: var(--chakra-colors-brand-secondary) !important;
  border-color: var(--chakra-colors-brand-secondary) !important;
  width: calc(100% - 24px);
  padding: 12px 10px 12px 10px;
  border-collapse: separate !important;
  border-radius: 70px;
  font-weight: normal;
  letter-spacing: -0.5px;
  line-height: 100%;
  text-align: center;
  text-decoration: none;
  color: var(--chakra-colors-neutral-0);
  display: block;
  margin-top: 3em;
  box-shadow: 0px 5px 8px #dddddd;
  cursor: pointer;
  align-self: flex-end !important;

  :hover {
    opacity: 0.5;
  }
`;

export const CircleProgress = styled(CircularProgressbar)`
  min-width: 100px;
  min-height: 100px;
  width: 10rem;
  height: 10rem;
  max-width: 400px;
  max-height: 400px;
  margin-bottom: 15px;
`;

export const RangeSlider = styled(ReactRange)`
  min-width: 100px;
  width: 70%;
`;

export const RangeWrapper = styled.div`
  width: 700px;
  max-width: 80vw;
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 50px;
`;

export const RangeSideText = styled.span`
  font-size: 1.2rem;
`;
