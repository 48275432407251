import styled from 'styled-components';

export const Container = styled.div`
  cursor: ${({ isLocked }) => (isLocked ? 'not-allowed' : 'pointer')};
  display: flex;
  width: 100%;
  height: 8rem;
  position: relative;
  border: none;
  border-radius: 0.625rem;
  opacity: ${({ isLocked }) => (isLocked ? 0.5 : 1)};
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? `url(${backgroundImage})` : ''};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px -50px 50px 25px rgba(0, 0, 0, 0.7);

  @media (min-width: 768px) {
    min-width: 30%;
    max-width: 30%;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  width: 100%;
  padding: 1rem;
  overflow: hidden;
`;

export const ContentOverlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
  height: 100%;
  width: 100%;
  color: #f0f0f0;
  overflow: hidden;
`;

export const CardTitle = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.5rem;

  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; // max number of lines to show
  -webkit-box-orient: vertical;
`;

export const SubTextWrapper = styled.div`
  font-size: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Duration = styled.div`
  position: absolute;
  color: var(--chakra-colors-neutral-0);
  bottom: 8px;
  right: 8px;
  font-size: 14px;
`;
