import React, { FC, createContext, useContext, useMemo } from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import {
  ThemeProvider as MaterialThemeProvider,
  createTheme as muiCreateTheme,
  THEME_ID
} from '@mui/material/styles';

import { theme } from '../theme';
import { useRemoteSettings } from './RemoteSettings';
import { useAppSlugContext } from './AppSlugContext';
import { ThemeContextProps, ThemeProviderProps } from '../types/contexts';
import LoadingSpinner from '../components/LoadingSpinner';

const ThemeContext = createContext<ThemeContextProps>({
  logo: '',
  theme: {}
});

function useTheme() {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useTheme must be used within an ThemeProvider');
  }

  return context;
}

const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  const { currentAppSlug } = useAppSlugContext();
  const { colors, images, isLoading } = useRemoteSettings();

  const appConfig: ThemeContextProps = useMemo(() => {
    // CheckingIn default values
    const result = {
      logo: images?.logoWeb || '',
      theme
    };

    if (isLoading) return result;

    if (currentAppSlug && colors) {
      // Old colors
      result.theme.colors.brand.primary = colors?.primary['500'];
      result.theme.colors.brand.secondary = colors?.secondary['500'];

      // New Ds Colors
      result.theme.colors.ds.brand.primary['800'] = colors?.primary['500'];
      result.theme.colors.ds.brand.accent['600'] = colors?.secondary['500'];

      result.theme.colors.forgotPassword = {
        ...colors.forgotPassword
      };
    }

    return {
      ...result,
      theme: extendTheme({
        ...result.theme
      })
    };
  }, [currentAppSlug, colors, images, isLoading]);

  return (
    <ThemeContext.Provider
      value={{
        ...appConfig
      }}
    >
      <ChakraProvider theme={appConfig.theme} resetCSS>
        <MaterialThemeProvider theme={{ [THEME_ID]: muiCreateTheme() }}>
          {isLoading ? <LoadingSpinner /> : <>{children}</>}
        </MaterialThemeProvider>
      </ChakraProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeProvider, useTheme };
