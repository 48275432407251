import styled from 'styled-components';
import { Divider as InnerDivider } from '@mui/material';
import { breakpoints } from '@checkingin/component-library';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  padding: 0rem 1.25rem;
  width: 100%;
`;

export const TitleContainer = styled.div`
  margin-bottom: 1.5rem;
`;

export const TitleText = styled.span`
  font-size: 1.5rem;
  line-height: 1.625rem;
  font-weight: 700;
  font-family: var(--chakra-fonts-body);
  color: hsl(120, 1%, 39%);
  text-align: center;
  margin-top: 1.5rem;

  @media (max-width: ${breakpoints.tablet}px) {
    font-size: 1rem;
    line-height: 1rem;
  }
`;

export const DescriptionContainer = styled.div`
  margin-bottom: 1rem;
`;

export const DescriptionText = styled.span`
  font-family: var(--chakra-fonts-body);
  font-size: 1rem;
  line-height: 1.375rem;

  @media (max-width: ${breakpoints.tablet}px) {
    font-size: 0.938rem;
    line-height: 1.313rem;
  }
`;

export const Divider = styled(InnerDivider)`
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.813rem;
  max-width: 617px;

  width: 100%;

  @media (min-width: ${breakpoints.tablet}px) {
    width: 80%;
  }

  margin-top: 1rem;
`;
