import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { SpinnerWrapper, SpinnerIMG, SpinnerText } from './style';

const LoadingSpinner = ({ message, showDefault, wrapper }) => {
  const { t } = useTranslation();

  return (
    <SpinnerWrapper wrapper={wrapper}>
      <SpinnerIMG alt={`${t('Loading')}...`} />
      {message && <SpinnerText>{message}</SpinnerText>}
      {showDefault && <SpinnerText>{t('Breathe')}...</SpinnerText>}
    </SpinnerWrapper>
  );
};

LoadingSpinner.propTypes = {
  message: PropTypes.string,
  showDefault: PropTypes.bool,
  wrapper: PropTypes.bool
};

LoadingSpinner.defaultProps = {
  message: null,
  showDefault: true,
  wrapper: false
};

export default LoadingSpinner;
