import styled from 'styled-components';

export const Container = styled.div`
  color: #636463;
  font-family: var(--chakra-fonts-body);
  font-size: 1.4em;
  background-color: var(--chakra-colors-neutral-0);
  box-shadow: 0px 2px 0px #fafafa;
  border-color: #fafafa;
  z-index: 1;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.div`
  margin-top: 0;
  color: #636463;
  font-family: var(--chakra-fonts-body);
  font-size: 1em;
  text-align: center !important;
  word-wrap: break-word;
  font-weight: 400;
  line-height: 1.875em;
`;

export const PreviousImage = styled.img`
  width: 1em;
  opacity: 0.5;
  transition: 350ms;

  :hover {
    opacity: 1;
  }
`;
