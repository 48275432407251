import styled from 'styled-components';
import { Avatar } from '@mui/material';
import { Select as InnerSelect, colors } from '@checkingin/component-library';

export const WizardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ContainerCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--chakra-colors-neutral-0);
  background-clip: border-box;
  border-radius: 0.25rem;
  border: none;
  box-shadow: 0 2px 6px 0 ${colors.black.lighter};
  text-align: center;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 60rem;
  min-height: 25vh;
`;

export const Title = styled.h1`
  font-size: 24px;
  color: #636463;
  text-align: left;
  border-bottom: solid ${colors.black.lighter} 1px;
  padding: 8px 0;
`;

export const Info = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding-bottom: 1.25rem;
  justify-content: center;
  align-items: center;
`;

export const Picture = styled(Avatar)`
  height: 10rem !important;
  width: 10rem !important;

  @media (min-width: 768px) {
    height: 20rem !important;
    width: 20rem !important;
  }
`;

export const Data = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const Select = styled(InnerSelect)`
  margin-top: 0.7rem;
`;
