import { gql } from '@apollo/client';

export const GET_USER_WHITE_LABEL = gql`
  query GetUserWhiteLabel {
    userWhiteLabel {
      id
      name
      slug
      groupId
      meta
    }
  }
`;
