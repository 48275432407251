import styled from 'styled-components';
import { colors } from '@checkingin/component-library';

export const Title = styled.h4`
  font-weight: 700;
  font-size: 1.5rem;
  margin: 0;
`;

export const LoadMore = styled.div`
  background-color: var(--chakra-colors-brand-primary) !important;
  border-color: var(--chakra-colors-brand-primary) !important;
  width: 20%;
  padding: 12px 10px 12px 10px;
  border-collapse: separate !important;
  border-radius: 70px;
  font-weight: normal;
  letter-spacing: -0.5px;
  line-height: 100%;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  display: block;
  margin-top: 3em;
  box-shadow: 0px 5px 8px #dddddd;
  cursor: pointer;
  align-self: center !important;

  :hover {
    opacity: 0.5;
  }
`;

export const ButtonToCheckin = styled.button`
  font-weight: 700;
  color: #297aff;
  border: 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;

export const MainContainer = styled.div`
  margin: 0.625rem 0.625rem 8rem 0.625rem;

  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  width: 90vw;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 1rem;
  max-width: 1024px;
  cursor: pointer;

  gap: 1rem;
`;

export const FilterTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FilterTitle = styled.span`
  font-size: 1rem;
  color: hsl(120, 1%, 52%);
`;

export const FilterOptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const FilterOption = styled.span`
  padding: 0.75rem;

  border: 1px solid ${colors.orange.normal};
  border-radius: 16px;

  background-color: ${({ selected }) =>
    selected ? `${colors.orange.normal}` : `var(--chakra-colors-neutral-0)`};
  font-size: 1rem;
  color: ${({ selected }) =>
    selected ? `var(--chakra-colors-neutral-0)` : `${colors.orange.normal}`};
`;
