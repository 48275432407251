import React, { Fragment } from 'react';
import {
  Box,
  Text,
  Flex,
  IconButton,
  Avatar,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  VStack,
  MenuDivider,
  useDisclosure
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CustomIcon } from '../CustomIcon';
import { CustomHeaderProps } from '../../../types/components';
import { useUserData } from '../../../contexts/UserDataContext';
import { AVATAR_LINKS } from '../../../constants/navigation';
import { HEADER_HEIGHT } from '../../../constants/components';
import { useTheme } from '../../../contexts/ThemeContext';

const Header: React.FC<CustomHeaderProps> = ({ onToggleSidebar }) => {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const { push } = useHistory();
  // @ts-ignore
  const { userData } = useUserData();
  const { logo } = useTheme();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      height={HEADER_HEIGHT}
      position="fixed"
      top={0}
      left={0}
      right={0}
      as="header"
      bg="ds.brand.primary.800"
      px={4}
      py={2}
      display="flex"
      zIndex={999}
    >
      <Flex width="100%" alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <IconButton
            aria-label="Open navigation menu"
            variant="ghost"
            color="ds.neutral.gray.0"
            icon={<FontAwesomeIcon icon={faBars} fontSize={24} />}
            onClick={onToggleSidebar}
            _hover={{
              opacity: 0.5
            }}
            cursor="pointer"
            zIndex={2000}
          />
          <Box
            ml={4}
            display="flex"
            alignItems="center"
            gap={4}
            overflow="hidden"
          >
            <Image
              src={logo}
              height={`calc(${HEADER_HEIGHT} - 0.5rem)`}
              width="auto"
              objectFit="contain"
              cursor="pointer"
              onClick={() => push('/')}
            />
            <Text fontSize="m" color="ds.neutral.gray.0">
              {userData?.group}
            </Text>
          </Box>
        </Flex>

        <Menu isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
          <MenuButton
            as={IconButton}
            icon={
              <Avatar
                size="sm"
                bg={isOpen ? 'ds.brand.primary.500' : 'ds.brand.primary.200'}
                color={isOpen ? 'ds.neutral.gray.0' : 'ds.brand.primary.800'}
                name={userData?.name}
              />
            }
            aria-label="Open settings menu"
            variant="ghost"
            color="ds.neutral.gray.0"
            cursor="pointer"
            _hover={{
              opacity: 1
            }}
            _active={{
              opacity: 1
            }}
          />
          <MenuList>
            <VStack
              display={{ base: 'none', md: 'flex' }}
              alignItems="flex-start"
              spacing="1"
              ml="3"
            >
              <Text fontSize="sm" fontWeight="bold" color="ds.text.general.800">
                {userData?.name}
              </Text>
              <Text fontSize="xs" color="ds.text.general.800">
                {userData?.groupName}
              </Text>
            </VStack>
            <MenuDivider />
            {AVATAR_LINKS(push).map((item, index) => (
              <Fragment key={`MenuItem-${index}`}>
                <MenuItem onClick={item.action}>
                  <CustomIcon
                    icon={item.icon}
                    size={20}
                    color="ds.text.general.800"
                    text={t(item.title)}
                    textProps={{
                      ml: 3,
                      color: 'ds.text.general.800'
                    }}
                  />
                </MenuItem>
                {AVATAR_LINKS(push).length - 1 > index ? <MenuDivider /> : null}
              </Fragment>
            ))}
            <MenuDivider />
            <MenuItem onClick={() => logout()}>
              <Text
                fontWeight="400"
                fontSize="sm"
                color="feedback.negative.100"
              >
                {t('Logout')}
              </Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  );
};

export { Header };
