import React, { createContext, useContext, useState } from 'react';

import { AppSlugContextProps, AppSlugProviderProps } from '../types/contexts';
import { AppSlugEnum } from '../types/whiteLabel';

const AppSlugContext = createContext<AppSlugContextProps>({
  currentAppSlug: AppSlugEnum.CHECKINGIN,
  setCurrentAppSlug: () => {}
});

function useAppSlugContext() {
  const context = useContext(AppSlugContext);

  if (!context) {
    throw new Error(
      'useAppSlugContext must be used within an AppSlugContextProvider'
    );
  }

  return context;
}

function AppSlugContextProvider({ children }: AppSlugProviderProps) {
  const [currentAppSlug, setCurrentAppSlug] = useState<AppSlugEnum>(
    AppSlugEnum.CHECKINGIN
  );

  return (
    <AppSlugContext.Provider value={{ currentAppSlug, setCurrentAppSlug }}>
      {children}
    </AppSlugContext.Provider>
  );
}

export { AppSlugContextProvider, useAppSlugContext };
