import styled from 'styled-components';
import { colors } from '@checkingin/component-library';

export const ContainerCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  word-wrap: break-word;
  background-color: var(--chakra-colors-neutral-0);
  background-clip: border-box;
  border-radius: 0.25rem;
  border: none;
  box-shadow: 0 2px 6px 0 ${colors.black.lighter};
  text-align: center;
  padding: 1rem;
  margin: 0.625rem 0.625rem 5rem 0.625rem;
  min-height: 25vh;
`;

export const CardBody = styled.div`
  padding: 1.25rem;
  margin-bottom: 2rem;
`;

export const SubHeader = styled.div`
  color: '#3c3c3e';
  text-align: 'center';
  font-size: '16px';
  font-weight: 600;
`;

export const QRCodeContainer = styled.div`
  height: 'auto';
  margin: '0 auto';
  maxwidth: 200;
  width: '100%';
`;
