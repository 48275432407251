import styled from 'styled-components';
import { CircularProgressbar } from 'react-circular-progressbar';

export const Container = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--chakra-colors-neutral-0);
  background-clip: border-box;
  border-radius: 0.25rem;
  border: none;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);

  text-align: center;

  width: 90%;
  padding: 1rem;
  max-width: 1024px;
  min-height: 10vh;
`;

export const CircleProgress = styled(CircularProgressbar)`
  width: 5rem;
  height: 5rem;
  max-width: 400px;
  max-width: 400px;
`;

export const CircleSection = styled.div`
  flex: 2;
`;

export const WordWeatherWrapper = styled.div`
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Word = styled.span`
  font-weight: bolder;
  line-height: 1.5rem;
  color: #212529;
  font-size: 1rem;
`;

export const TimeLine = styled.span`
  font-size: 80%;
  font-weight: 400;
  color: #636463;
`;

export const WeatherLine = styled.div`
  font-size: 80%;
  font-weight: 400;
  color: #636463;
`;

export const WeatherImage = styled.img`
  width: 16px;
  margin: 0.25rem;
`;

export const PhraseWrapper = styled.div`
  display: flex;
  flex: 4;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: justify;
  padding: 0.625rem;
`;

export const ActionsWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  right: 20px;
  display: flex;
  gap: 1rem;
`;

export const Delete = styled(WeatherImage)`
  cursor: pointer;
`;

export const Edit = styled(Delete)``;
