import styled, { css } from 'styled-components';
import { CircularProgress as InnerCircularProgress } from '@mui/material';
import LockOutIcon from '@mui/icons-material/LockOutlined';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--chakra-colors-forgotPassword-pageBackgroundColor);
`;

export const SubContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  max-width: 320px;
`;

export const LogoImage = styled.img.attrs({
  alt: 'Logo image'
})`
  max-height: 58px;
  width: auto;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--chakra-colors-forgotPassword-topBackgroundColor);

  width: 100%;
  padding: 0.75rem 0 0.75rem 0;
  border-radius: 0.25rem 0.25rem 0 0;
`;

export const TitleInnerContainer = styled.div`
  margin-top: 1rem;
`;

export const Title = styled.h1`
  font-size: 1.25rem;
  color: var(--chakra-colors-forgotPassword-topTextColor);
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  background: var(--chakra-colors-neutral-0);
  width: 100%;
  border-radius: 0 0 0.25rem 0.25rem;
`;

export const InputInnerContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;

  width: 80%;
`;

export const InputSecondInputContainer = styled.div`
  margin-top: 0.75rem;
`;

export const InputText = styled.span`
  font-size: 0.875rem;
  color: var(--chakra-colors-neutral-700);
`;

export const InputTextError = styled(InputText)`
  font-weight: bold;
`;

export const FormInnerContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem 0 1.5rem 0;
`;

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 80%;
  text-align: center;
`;

export const DangerRoundedIcon = styled(ErrorOutlineRoundedIcon)`
  font-size: 3.75rem !important;
  color: var(--chakra-colors-neutral-700) !important;
`;

export const ConfirmContainer = styled.div`
  flex: 1;
  display: flex;
  background-color: var(--chakra-colors-brand-primary);
  width: 100%;
  padding: 0.75rem 0 0.75rem 0;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 0.25rem 0.25rem;
`;

export const ConfirmButtonImage = styled.img.attrs({
  alt: 'Confirm button image'
})<{ disabled: boolean }>`
  height: 42px;
  width: 43px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
    `}
`;

export const LockOutlinedIcon = styled(LockOutIcon)`
  color: var(--chakra-colors-neutral-900) !important;
  font-size: 0.875rem !important;
`;

export const CircularProgress = styled(InnerCircularProgress)<{
  customColor?: string;
}>`
  color: ${({ customColor }) =>
    customColor || 'var(--chakra-colors-brand-primary-800)'} !important;
`;
