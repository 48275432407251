import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon as InnerFontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar as InnerAvatar } from '@mui/material';

export const Container = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.625rem;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--chakra-colors-neutral-0);
  background-clip: border-box;
  border-radius: 0.25rem;
  border: none;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);

  text-align: center;

  width: 90%;
  padding: 1rem;
  max-width: 1024px;
  min-height: 10vh;
`;

export const Word = styled.span`
  font-weight: bolder;
  line-height: 1.5;
  color: #212529;
  font-size: 1rem;
`;

export const TimeLine = styled.span`
  font-size: 80%;
  font-weight: 400;
  color: #636463;
`;

export const PhraseWrapper = styled.div`
  display: flex;
  flex: 4;

  padding: 0.625rem;
`;

export const ExerciseImageContainer = styled.div`
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: center;
`;

export const ExerciseIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 28px;
  background-color: var(--chakra-colors-brand-primary);
`;

export const Avatar = styled(InnerAvatar)`
  width: 56px !important;
  height: 56px !important;
`;

export const ExerciseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ExerciseInformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export const FontAwesomeIcon = styled(
  // eslint-disable-next-line no-unused-vars
  ({ exerciseIcon, ...props }) => <InnerFontAwesomeIcon {...props} />
)`
  font-size: ${({ exerciseIcon }) => (exerciseIcon ? '1.75rem' : '1rem')};
  color: ${({ rating, exerciseIcon }) =>
    rating
      ? 'green'
      : `${exerciseIcon ? '#f3f1e8' : 'var(--chakra-colors-neutral-1100)'}`};
`;
