import { initializeApp } from 'firebase/app';
import { getRemoteConfig, fetchAndActivate } from 'firebase/remote-config';

export const firebaseService = async (appSlug: string) => {
  let firebaseConfig = process.env.REACT_APP_CHECKINGIN_FIREBASE_CONFIG;

  if (appSlug === 'squamish') {
    firebaseConfig = process.env.REACT_APP_SQUAMISH_FIREBASE_CONFIG;
  } else if (appSlug === 'stzuminus') {
    firebaseConfig = process.env.REACT_APP_STZUMINUS_FIREBASE_CONFIG;
  } else if (appSlug === 'tlaamin') {
    firebaseConfig = process.env.REACT_APP_TLAAMIN_FIREBASE_CONFIG;
  } else if (appSlug === 'sqewlets') {
    firebaseConfig = process.env.REACT_APP_SQEWLETS_FIREBASE_CONFIG;
  }

  if (!firebaseConfig) {
    throw new Error('Firebase config not defined');
  }

  const firebaseConfigParsed = JSON.parse(firebaseConfig);

  const app = initializeApp(firebaseConfigParsed, appSlug);
  const remoteConfig = getRemoteConfig(app);

  remoteConfig.settings.minimumFetchIntervalMillis = 0;

  try {
    await fetchAndActivate(remoteConfig);
    remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
  } catch (error) {
    console.error(`Error fetching remote config for ${appSlug}:`, error);
  }

  return { app, remoteConfig };
};
