import { useLazyQuery } from '@apollo/client';

import { GET_USER_WHITE_LABEL } from '../services/graphql-queries/whiteLabels';
import { UseWhiteLabelsProps, GetUserWhiteLabelGQL } from '../types/whiteLabel';

export const useWhiteLabels = (): UseWhiteLabelsProps => {
  const [
    getUserWhiteLabel,
    {
      data: userWhiteLabel,
      error: errorGetUserWhiteLabel,
      loading: loadingGetUserWhiteLabel
    }
  ] = useLazyQuery<GetUserWhiteLabelGQL>(GET_USER_WHITE_LABEL);

  return {
    userWhiteLabel,

    // Errors
    errorGetUserWhiteLabel,

    // Loading
    loadingGetUserWhiteLabel,

    // Functions
    getUserWhiteLabel
  };
};
