import styled from 'styled-components';

export const MainContainer = styled.div`
  margin: 0.625rem 0.625rem 8rem 0.625rem;
  padding: 10px 15px 40px 15px;
  background-color: var(--chakra-colors-neutral-0);
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  max-width: 960px;
  width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const NotAvailableContainer = styled.h3`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 30px;
`;

export const NotAvailableTitle = styled.h3`
  color: var(--chakra-colors-brand-primary);
  font-weight: 700;
`;

export const Title = styled.h1`
  margin-top: 10px;
  font-size: 25px;
  color: #636463;
`;

export const Line = styled.hr`
  margin: 30px 0;
`;

export const CardsContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 15px;
  padding: 0 10px;
`;
