import React from 'react';

import { AppSlugContextProvider } from './contexts/AppSlugContext';
import { RemoteSettingsProvider } from './contexts/RemoteSettings';
import { ThemeProvider } from './contexts/ThemeContext';
import { AppRoutes } from './routes';

function App() {
  return (
    <AppSlugContextProvider>
      <RemoteSettingsProvider>
        <ThemeProvider>
          <AppRoutes />
        </ThemeProvider>
      </RemoteSettingsProvider>
    </AppSlugContextProvider>
  );
}

export { App };
